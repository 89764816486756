<script>
  import '@lottiefiles/lottie-player'

  /** A Lottie animation. */
  export default {
    props: {
      /** The URL to the Lottie JSON file, or the Lottie data object. */
      src: {
        type: [String, Object],
        required: true,
      },
      /** Whether the animation should play automatically. */
      autoplay: {
        type: Boolean,
        default: false,
      },
      /** Whether the animation should loop. */
      loop: {
        type: Boolean,
        default: false,
      },
      /** The animation’s aspect ratio (width / height). When set to a different aspect ratio than the animation, it will be contained, not cropped. */
      aspectRatio: {
        type: Number,
        default: undefined,
      },
    },
    data() {
      return {
        lottieRef: null,
        readyPromise: null,
      }
    },
    computed: {
      finalSrc() {
        if (typeof this.src === 'object') {
          return JSON.stringify(this.src)
        }
        return this.src
      },
    },
    created() {
      this.readyPromise = (async () => {
        await this.$waitForValue(() => this.lottieRef)
        return new Promise((resolve) => {
          this.lottieRef.addEventListener('ready', resolve)
        })
      })()
    },
    methods: {
      async play() {
        await this.readyPromise
        this.lottieRef.play()
      },
      async pause() {
        await this.readyPromise
        this.lottieRef.pause()
      },
      async stop() {
        await this.readyPromise
        this.lottieRef.stop()
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="aspectRatio" :style="{ paddingBottom: `${(1 / aspectRatio) * 100}%` }" />
    <lottie-player
      v-ref="lottieRef"
      :src="finalSrc"
      :autoplay="autoplay"
      :loop="loop"
      :class="`${aspectRatio ? 'absolute left-0 top-0 w-full h-full' : ''}`"
    />
  </div>
</template>
